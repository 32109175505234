<template>
  <div class="order">
    <!-- tab切换 -->
    <div class="order-nav">
      <div :class="['order-nav-link',{'ac':status == 0}]" @click="status = 0">全部</div>
      <div :class="['order-nav-link',{'ac':status == 2}]" @click="status = 2">待发货</div>
      <div :class="['order-nav-link',{'ac':status == 3}]" @click="status = 3">待收货</div>
      <div :class="['order-nav-link',{'ac':status == 4}]" @click="status = 4">已完成</div>
    </div>
    <!-- 订单列表 -->
    <order-list :orderList="orderList" :isLoad='isLoad' :loadLastText='loadLastText' @getMore='getIntegralOrder' ref="orderListRef"></order-list>
  </div>
</template>
<style lang="scss" scoped>
.order {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f6f6f6;
  padding-top: 1.2rem;

  * {
    box-sizing: border-box;
  }

  .order-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    background: #fff;

    .order-nav-link {
      height: 1.2rem;
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.28rem;
      color: #afafaf;
      border-bottom: 1px solid #fff;
    }

    .order-nav-link.ac {
      color: #e50012;
      border-bottom: 1px solid #e50012;
      font-weight: bold;
    }
  }
}
</style>
<script>
import { getIntegralOrder } from "@/http/api";
import OrderList from "./OrderChildren/OrderList"

export default {
  components: {
    OrderList
  },
  data() {
    return {
      status: 0,
      orderList: [],
			pages: 1,
			loadLastText: false,
			isLoad: false
    };
  },
  mounted() {
    if ((this.userInfo = this.$login.isLogin(window.location.href))) {
      this.getIntegralOrder();
    }
  },
  methods: {
    //获取积分订单列表
    async getIntegralOrder() {
      const res = await getIntegralOrder({
        user_id: this.userInfo.user_id,
        order_status: this.status,
        pages: this.pages
      })
      // console.log(res);
      this.isLoad = true;
      this.$refs.orderListRef.changeLoading()
      if(res.code == '200'){
				this.orderList = this.orderList.concat(res.data);
				if(res.data.length < res.numPage){
					this.loadLastText = true;
				}else {
					this.pages ++;
					this.loadLastText = false;
				}
			}
    }
  },
  watch: {
    status() {
      this.pages = 1;
			this.orderList = [];
			this.isLoad = false;
			this.loadLastText = false;
      this.getIntegralOrder();
    }
  },
  computed: {},
  filters: {},
};
</script>
