<template>
  <div class="order-grid">
    <div class="order-hd">
      <div class="order-num">订单编号：{{ item.order_no }}</div>
      <div class="order-status">{{ statusInfo.statusText }}</div>
    </div>
    <div class="goods-list">
      <div class="goods-grid">
        <img :src="item.goods_lists.goods_image" alt="" />
        <div class="goods-info">
          <p class="goods-name">{{ item.goods_lists.goods_name }}</p>
          <div class="goods-spec" v-if="item.goods_lists.specs_item_value != ''">{{ item.goods_lists.specs_item_value }}</div>
          <div class="row">
            <div class="goods-price">{{ item.goods_lists.buy_points }}积分</div>
            <div class="goods-num">×{{ item.goods_lists.goods_nums }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-bot">
      <div class="btn red"  @click="deleteOrder(item.order_id)"  v-if="item.order_status == 0 || item.order_status == 4">删除订单</div>
      <div class="btn red"  @click="toReceive(item.order_id)"  v-if="item.order_status == 0">确认收货</div>
      <!--
      <div class="btn red" v-if="statusInfo.showBtn" @click="toReceive">{{ statusInfo.btnText }}</div>
      -->
      <router-link tag="div" :to="'/MallOrderDetails?id=' + item.order_id" class="btn">订单详情</router-link>
    </div>
  </div>
</template>

<script>
import { receiveIntegralOrder } from '@/http/api'
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isPost: false
    };
  },
  computed: {
    statusInfo() {
      let orderStatus = this.item.order_status;
      switch(orderStatus){
        case 0:
          return {
            statusText: '已取消',
            showBtn: false,
          }
        case 1:
          return {
            statusText: '待支付',
            showBtn: false,
          }
        case 2:
          return {
            statusText: '待发货',
            showBtn: false,
          }
        case 3:
          return {
            statusText: '待收货',
            showBtn: true,
            btnText: '确认收货'
          }
        case 4:
          return {
            statusText: '已完成',
            showBtn: false,
          }
        default:
          return {
            statusText: '未知',
            showBtn: false,
          }
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    if ((this.userInfo = this.$login.isLogin(window.location.href))) {}
  },
  methods: {
    deleteOrder(order_id) {
        // let res
        this.$dialog.confirm({
            title: '提示',
            message: '确认要删除这个订单吗？',
        })
        .then(() => {
            this.deleteIntegralOrder(order_id)
        })
        .catch(() => {
            // on cancel
        }); 
  
    },
    async deleteIntegralOrder(order_id) {
      const res = await deleteIntegralOrder({
        user_id: this.userInfo.user_id,
        order_id: order_id
      })
	  if(res.code == 200){
        this.$toast.success("删除成功"); 
      }else {
        this.$toast.fail("删除失败");
      }
    },
  
    toReceive(order_id) {
      if(this.isPost) {
        this.$toast('请勿重复提交');
      }else {
        this.$dialog.confirm({
          title: '提示',
          message: '请确保您已收到货物，收货后如需售后请联系客服',
          confirmButtonText: '收到了',
          cancelButtonText: '没收到',
        })
        .then(() => {
          this.receiveIntegralOrder(order_id)
        })
      }
    },
    async receiveIntegralOrder(order_id) {
      this.isPost = true;
      const res = await receiveIntegralOrder({
        user_id: this.userInfo.user_id,
        order_id: order_id
      })
      this.isPost = false;
			if(res.code == 200){
        this.$toast.success('操作成功');
        this.item.order_status = 4
      }else {
        Toast(res.msgs);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.order-grid {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 1vw;
  margin-bottom: 0.2rem;

  .order-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.84rem;
    font-size: 0.28rem;
    border-bottom: 1px solid #eee;

    .order-status {
      color: #da1313;
    }
  }

  .goods-list {
    border-bottom: 1px solid #eee;
  }

  .goods-grid {
    height: 28vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 24vw;
      height: 24vw;
      object-fit: cover;
    }

    .goods-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 24vw;
      width: 64vw;

      .goods-name {
        font-size: 0.26rem;
        color: #323233;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .goods-spec {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 0.1rem 0.15rem;
        font-size: 0.24rem;
        color: #848484;
        width: 2rem;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .goods-price {
          font-size: 0.3rem;
          font-weight: bold;
          color: #da1313;
        }

        .goods-num {
          font-size: 0.24rem;
        }
      }
    }
  }

  .order-bot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 1.13rem;

    .btn {
      width: 1.4rem;
      height: 0.6rem;
      border-radius: 5px;
      border: 1px solid #c0c0c0;
      color: #585858;
      font-size: 0.26rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.35rem;
    }

    .btn.red {
      color: #da1313;
      border: 1px solid #da1313;
    }
  }
}
</style>
